<template>
  <div v-if="$route.params.cashoutPlantTreeAvailable == true">
    <v-container>
      <p v-html="$t('evertreen_intro')"></p>

      <p><a @click="openDialog({ url: 'https://evertreen.com/faqs/' })">{{ $t('frequently_asked_questions')}}</a></p>

      <v-stepper v-model="e6" vertical transparent>
        <v-stepper-step :complete="e6 > 1" step="1">{{ $t("select_a_tree") }}</v-stepper-step>

        <v-stepper-content step="1">
          <v-card class="mx-auto my-4" v-for="(t, t_i) in trees" :key="t_i">
            <v-img :src="t.image" height="200px"
              class="white--text align-end"
              gradient="to bottom, rgba(0,0,0,.1), rgba(0,0,0,.5)"
            >
              <v-card-title v-text="t.title"></v-card-title>
            </v-img>

            <v-card-title>{{ `${calculatePoints(t.amountInCurrency)} ${$t('points')} (£${t.amountInCurrency})` }}</v-card-title>
            <v-card-subtitle>{{ t.subtitle }}</v-card-subtitle>

            <v-card-actions>
              <v-btn color="primary" @click="openDialog(t)" text>{{ $t("read_more") }}</v-btn>
              <v-btn color="primary" @click="plantThis(t)" text
                :disabled="calculatePoints(t.amountInCurrency) >= $store.state.userProfile.totalPoints"
              >{{ $t("plant_this") }}</v-btn>
            </v-card-actions>
          </v-card>

          <iFrame v-if="modal" 
            :url="url"
            v-on:changeModal="closeModal"            
          />
        </v-stepper-content>

        <v-stepper-step :complete="e6 > 2" step="2">{{ $t('confirm_selections') }}</v-stepper-step>

        <v-stepper-content step="2">
          <v-text-field
            :label="$t('name')"
            v-model="cashOutDetails.title"
            readonly
          />

          <v-text-field
            :label="$t('points_to_redeem')"
            v-model="cashOutDetails.points"
            readonly
          />

          <v-btn color="primary" @click="submitPlantTreeRequest()">{{ $t('continue') }}</v-btn>
          <v-btn @click="e6 = 1" text>{{ $t('back') }}</v-btn>
        </v-stepper-content>

        <v-stepper-step step="3">{{ $t('thank_you') }}</v-stepper-step>
        <v-stepper-content step="3">
          <p>{{ $t('points_confirmation_email', { 0: cashOutDetails.points })}}</p>
        </v-stepper-content>
      </v-stepper>
    </v-container>

  </div>

  <div v-else>
    <p v-html="$t('cashout_everteen_not_available_reasons')"></p>
  </div>
</template>

<script>
import { DataService } from "../../services/DataService";
import iFrame from "./../../components/iFrame"

const dataService = new DataService();

export default {
  components: {
    iFrame
  },
  data() {
    return {
      e6: 1,
      modal: false,
      url: null,
      conversionRatio: 139,
      trees: [
        {
          image: "https://evertreen.com/wp-content/uploads/2020/09/giant-aloe-vera-1546738_640-230x350.jpg",
          title: "Aloe Vera – CO2 25 Kg",
          amountInCurrency: 1,
          url: "https://evertreen.com/Tree/aloe-vera-co2-25-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/05/Copertina-prodotto-Immagine-fiore-di-vaniglia-230x350.png",
          title: "Vanilla Plant – CO2 90 Kg",
          amountInCurrency: 1.99,
          url: "https://evertreen.com/Tree/vanilla/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/05/Pepe-copertina-230x350.jpg",
          title: "Pepper Plant – CO2 90 Kg",
          amountInCurrency: 1.99,
          url: "https://evertreen.com/Tree/pepper-plant/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/09/Turmeric-flower-230x350.jpg",
          title: "Turmeric Plant – CO2 30 Kg",
          amountInCurrency: 4,
          url: "https://evertreen.com/Tree/turmenic-plant-co2-30-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/09/papaya-173660_640-230x350.jpg",
          title: "Papaya – CO2 170 Kg",
          amountInCurrency: 5,
          url: "https://evertreen.com/Tree/papaya-co2-170-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/White-Hawthorn-230x350.jpg",
          title: "White Hawthorn – CO2 40 Kg",
          amountInCurrency: 7,
          url: "https://evertreen.com/Tree/white-hawthorn-co2-40-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/06/Neem-230x337.jpg",
          title: "Neem Tree – CO2 396 Kg",
          amountInCurrency: 7.50,
          url: "https://evertreen.com/Tree/neem-tree-kenya-co2-396-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/lemon-tree-1-230x350.jpg",
          title: "Lemon – CO2 100 Kg",
          amountInCurrency: 7.50,
          url: "https://evertreen.com/Tree/lemon-co2-100-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/OrangeTreewithFruits3dsmodel001.jpgFB693193-FD73-4771-8F2B-FA3D455B9EC1Large-230x337.jpg",
          title: "Orange – CO2 200 Kg",
          amountInCurrency: 8.50,
          url: "https://evertreen.com/Tree/orange-co2-200-kg-subscription/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/Picture1-230x350.png",
          title: "Holm Oak – CO2 160 Kg",
          amountInCurrency: 8.50,
          url: "https://evertreen.com/Tree/holm-oak-co2-60-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/oak-354641_640-230x350.jpg",
          title: "Catalan Oak – CO2 100 Kg",
          amountInCurrency: 8.50,
          url: "https://evertreen.com/Tree/catalan-oak-co2-100-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/Evergreen-Rose2-230x350.jpg",
          title: "Evergreen Rose – CO2 100 Kg",
          amountInCurrency: 8.50,
          url: "https://evertreen.com/Tree/evergreen-rose-co2-150-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/06/Giant-Monkey-Pod-tree-1-230x350.jpg",
          title: "Giant Monkey Pod – CO2 562 Kg",
          amountInCurrency: 8.70,
          url: "https://evertreen.com/Tree/giant-monkey-pod/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/06/Pochote-1-230x350.jpg",
          title: "Pochote – CO2 493 Kg",
          amountInCurrency: 8.70,
          url: "https://evertreen.com/Tree/1050/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/06/Pacific-Coast-Mahogany-230x350.jpg",
          title: "Pacific Coast Mahogany – CO2 887 Kg",
          amountInCurrency: 9.50,
          url: "https://evertreen.com/Tree/pacific-coast-mahogany/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/2014-12-02-02-230x350.jpg",
          title: "Pink Pepper – CO2 150 Kg",
          amountInCurrency: 9.50,
          url: "https://evertreen.com/Tree/pink-pepper-co2-150-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/1-1-230x350.png",
          title: "Narrow-leafed Ash – CO2 780 Kg",
          amountInCurrency: 9.50,
          url: "https://evertreen.com/Tree/narrow-leafed-ash-co2-220-kg/"
        },
        {
          image: "https://evertreen.com/wp-content/uploads/2020/10/Picture1-2-230x350.png",
          title: "Sessile Oak – CO2 890 Kg",
          amountInCurrency: 9.50,
          url: "https://evertreen.com/Tree/sessile-oak-co2-250-kg/"
        },
      ],
      cashOutDetails: {},
    }
  },

  mounted() {
  },
  
  methods: {
    closeModal(event) {
      this.modal = event
    },
    openDialog(t) {
      this.url = t.url
      this.modal = true
    },
    plantThis(t) {
      if (this.$store.state.userProfile.totalPoints >= this.calculatePoints(t.amountInCurrency)) {        
        this.cashOutDetails = {
          title: t.title,
          currency: "GBP",
          amountInCurrency: t.amountInCurrency,
          points: this.calculatePoints(t.amountInCurrency),
          totalPoints: this.$store.state.userProfile.totalPoints,
        }

        this.e6 = 2
      } else {
        this.$store.state.snackBarText = this.$t('min_points_not_met')
        this.$store.state.showSnackBar = true
      }
    },
    calculatePoints(price) {
      return Math.ceil(this.conversionRatio * price)
    },
    submitPlantTreeRequest() {
      this.$store.state.loader = true
      dataService.plantTreeReward(this.$store.state.token, { cashoutData: this.cashOutDetails }).then(res => {
        if (res.error != true) {
          this.e6 = 3
          this.$store.state.loader = false
          this.$store.state.userProfile = res.userProfile
        } else {
          this.$store.state.loader = false
          this.$store.state.snackBarText = res.message
          this.$store.state.showSnackBar = true
        }
      })
    }
  },
}
</script>
