<template>
  <v-dialog v-model="modal" scrollable>
    <v-card height="90vh">
      <v-btn fab dark large color="primary" fixed right top @click="$emit('changeModal', false)">
        <v-icon dark>mdi-close</v-icon>
      </v-btn>
      <iframe :src="url"></iframe>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    url: String
  },
  data() {
    return {
      modal: true
    } 
  }
}
</script>

<style scoped>
iframe {
  display: block;       /* iframes are inline by default */
  background: #000;
  border: none;         /* Reset default border */
  height: 100%;
  width: 100%;
}
</style>